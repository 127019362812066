<template>
  <div>
    <CitasComponent />
  </div>
</template>

<script>
import CitasComponent from "@/components/Citas.vue";

export default {
  name: 'Agenda_view',

  components: {
    CitasComponent,
  },
};
</script>

<style scoped>

</style>
