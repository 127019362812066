<template>
  <div>
    <AgendaNailsComponent />
  </div>
</template>

<script>
import AgendaNailsComponent from "@/components/AgendaNails.vue";

export default {
  name: 'Nails_view',

  components: {
    AgendaNailsComponent,
  },
};

</script>

<style scoped>

</style>